<template>
<div class="banner" v-if="rnd">
    <img :src="rnd.banner.image"/>
    <div class="banner__text">{{rnd.banner.title}}</div>
</div>
    <div class="developbackground" v-if="rnd">
    <div class="develop container">
        <div class="develop__plan" v-html="descriptionWithoutStyle1">
        </div>
        <div class="line"></div>
        <div class="develop__program" v-html="descriptionWithoutStyle2">
        </div>
        <div class="develop__production">
            <div class="develop__production__title">
                <p>{{ $t('develop1') }}</p>
            </div>
            <div class="develop__production__block">
                <img :src="rnd.rnds[0].section3_image"/>
                <div class="develop__production__block__text">
                    {{ rnd.rnds[0].section3_text }}
                </div>
            </div>
            <img :src="rnd.rnds[0].section3_banner" class="develop__production__block__banner"/>
        </div>
        <div class="develop__achievements">
            <div class="develop__achievements__top">
                <div class="develop__achievements__top__title">
                <p>{{ $t('develop2') }}</p>
                </div>
                <div class="develop__achievements__top__right">
                    <img src="@/assets/newImages/arrow1.svg" class="arrow1"/>
                    <div class="develop__achievements__top__right__pagination">
                      <span class="develop__achievements__top__right__pagination__p1">0</span> 
                      <span class="develop__achievements__top__right__pagination__p2">0</span> 
                    </div>
                    <img src="@/assets/newImages/arrow2.svg" class="arrow2" />
                </div>
            </div>
            <swiper   
                    :lazy="true" :loop="false" 
                    :speed="1000" 
                    :pagination="{ 
                      el: '.develop__achievements__top__right__pagination', 
                      type: 'fraction', 
                      currentClass: 'develop__achievements__top__right__pagination__p1', 
                      totalClass: 'develop__achievements__top__right__pagination__p2' 
                    }" 
                    :navigation="{ 
                      prevEl: '.arrow1', 
                      nextEl: '.arrow2' 
                    }" 
                    :breakpoints="{ 
                        1000: { 
                          slidesPerView: 1, 
                          spaceBetween: 30, 
                        }, 
                        300: { 
                          slidesPerView: 1, 
                          spaceBetween: 20, 
                        } 
                    }" 
                    > 
                    <swiper-slide v-for="item in rnd.rnds[0].rndAchievements" :key="item" class="develop__achievements__swipercard"> 
                        <div class="develop__achievements__swipercard__img">
                            <img :src="item.image"/> 
                        </div>
                        <div class="develop__achievements__swipercard__text">
                            <p v-html="item.text"></p>
                        </div>
                    </swiper-slide> 
                  </swiper>
        </div>
    </div>
</div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore from "swiper"; 
import { useHead } from "@vueuse/head"
import { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
export default {
    setup() {
        useHead({
            title: "Исследования и разработки",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/develop'},
            ],
        })
    },
    components: { Swiper, SwiperSlide },
    data(){
        return{
            rnd: false
        }
    },
    async created() {
        this.getPage()    
    },
    methods:{
        async getPage() {            
            await this.$axios.get(`/world/rnd?lang=ru`)
            .then(res => {
                this.rnd = res.data
            })
            .catch(err => {
            })
        },
    },
    computed: {
        descriptionWithoutStyle1() {
            if (this.rnd) {
            return this.rnd?.rnds[0]?.section1.replace(/<img(.*?)style="(.*?)"(.*?)>/gi, '<img$1$3>');
            } else {
            return '';
            }
        },
        descriptionWithoutStyle2() {
            if (this.rnd) {
            return this.rnd?.rnds[0]?.section2.replace(/<img(.*?)style="(.*?)"(.*?)>/gi, '<img$1$3>');
            } else {
            return '';
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 2.7;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
&__text{
    position: absolute;
    color: rgb(255, 255, 255);
    font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    top:50%;
    left: 15%;  
    font-weight: 700;
}
}
.swiper{
    width: 100%;
}
.line{
    border: 1.5px solid rgba(122, 111, 111, 0.534);
}
.developbackground{
    background-color: #e4e4e5;
}
    .develop{
        display: flex;
        flex-direction: column;
        gap: min(max(48px, calc(3rem + ((1vw - 10.24px) * 2.4554))), 70px);;
        padding-top: min(max(48px, calc(3rem + ((1vw - 10.24px) * 2.4554))), 70px);
        padding-bottom: min(max(48px, calc(3rem + ((1vw - 10.24px) * 2.4554))), 70px);
        @media(max-width:1024px){
            padding-top: 75px;
            padding-left: 10px;
            padding-right: 10px;
        }
        &__plan{
            display: flex;
            flex-direction: column;
            gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            @media(max-width:1024px){
                gap: 10px;
            }
            &__title{
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                color: #282828;
                font-weight: 700;
                @media(max-width:867px){
                    font-size: 24px;
                }
                
            }
        }
        &__program{
            display: flex;
            flex-direction: column;
            gap: min(max(32px, calc(2rem + ((1vw - 10.24px) * 2.0089))), 50px);
            @media(max-width:867px){
                    gap: 20px;
                }
            &__title{
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                color: #282828;
                font-weight: 700;
                @media(max-width:867px){
                    font-size: 24px;
                }
            }
            &__sub{
                font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
                color: #282828;
                font-weight: 500;
                line-height: 2;
                @media(max-width:867px){
                    font-size: 14px;
                }
            }
            &__text{
                font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
                color: #282828;
                line-height: 2;
                @media(max-width:867px){
                    font-size: 14px;
                }
            }
        }
        &__production{
            display: flex;
            flex-direction: column;
            gap: min(max(32px, calc(2rem + ((1vw - 10.24px) * 2.0089))), 50px);
            @media(max-width:867px){
                    gap: 20px;
                }
            &__title{
                font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                color: #282828;
                font-weight: 700;
                @media(max-width:867px){
                    font-size: 24px;
                }
            }
            &__block{
                display: flex;
                @media(max-width:867px){
                    flex-direction: column;
                }
                img{
                    width: 50%;
                    aspect-ratio: 1.496;
                    object-fit: cover;
                    @media(max-width:867px){
                    width: 100%;
                }
                }
                &__text{
                    width: 50%;
                    padding: min(max(36px, calc(2.25rem + ((1vw - 10.24px) * 2.6786))), 60px)  min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                    line-height: 2;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
                    color: #282828;
                    background: white;
                    @media(max-width:867px){
                        width: 100%;
                        padding: 10px;
                        font-size: 14px;
                    }
                }
            }
            &__banner{
                width: 100%;
                aspect-ratio: 2.60;
                object-fit: cover;
            }
        }
        &__achievements{
                display: flex;
                flex-direction: column;
                gap: min(max(32px, calc(2rem + ((1vw - 10.24px) * 2.0089))), 50px);
                &__top{
                    display: flex;
                    justify-content: space-between;
                    &__title{
                        font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
                        color: #282828;
                        font-weight: 700;
                        @media(max-width:867px){
                            font-size: 24px;
                        }
                    }
                    &__right{
                        display: flex;
                        align-items: center;
                        gap: min(max(32px, calc(2rem + ((1vw - 10.24px) * 2.0089))), 50px);
                        @media(max-width:867px){
                            gap: 10px;
                            padding-right: 20px;
                        }
                        img{
                            width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                            height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                            cursor: pointer;
                            @media(max-width:867px){
                                width: 15px;
                                height: 15px;
                            }
                        }
                        &__pagination{
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
                &__swipercard{
                    display: flex;
                    width: 100%;
                    @media(max-width:867px){
                            flex-direction: column;
                        }
                    &__img{
                        width: 50%;
                        aspect-ratio: 1.238;
                        @media(max-width:867px){
                           width: 100%;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            display: flex;
                        }
                    }
                    &__text{
                        width: 50%;
                        padding: min(max(48px, calc(3rem + ((1vw - 10.24px) * 3.5714))), 80px) min(max(48px, calc(3rem + ((1vw - 10.24px) * 3.5714))), 80px);
                        background: white;
                        line-height: 2;
                        font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
                        @media(max-width:867px){
                            width: 100%;
                            padding: 10px;
                        }
                    }
                }
            }
    }
</style>